'use strict';
const $ = (window.jQuery = window.$ = require('jquery'));

let Selector = {
  carousel: '.js-carousel'
};

let breakpoints = {
  xs: 0,
  sm: 544,
  md: 769,
  lg: 992,
  xl: 1440
};

let bindEvent = () => {
  console.log('Carousel online');
  if (!$(Selector.carousel).data().resolution) {
    $(Selector.carousel).slick();
  } else {
    // activate online under a certain resolution
    let carouselResolution = $(Selector.carousel).data().resolution;
    if (window.innerWidth < breakpoints[carouselResolution]) {
      $(Selector.carousel).slick();
    }
  }
};

module.exports = function() {
  if ($(Selector.carousel).length > 0) bindEvent();
};
