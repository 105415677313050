'use strict';

function updateCommission(data) {
  if (data.items.length > 0) {
    if (data.totalCommissionsAmount.value > 0) {
      $('.commission-total')
        .empty()
        .append(data.totalCommissionsAmount.value);
      $('.commission-total-tax')
        .empty()
        .append(data.totalCommissionsTax.value);
      $('.commission-discount').show();
    } else {
      $('.commission-discount').hide();
    }
  }
}

$(document).ready(function() {
  require('./components/product-fixaddtocart')('.accessory-item');
  // trick to make standard add-to-cart work for the accessories
  if ($('.cart-page').length) {
    $('body').on('cart:update promotion:success product:afterAddToCart', function() {
      location.reload();
    });
  }

  $('body').on('click.addPrefilter', '.cart-delete-confirmation-btn', function(e) {
    setTimeout(function() {
      window.location.reload();
    }, 600);
  });

  $('body').one('click.addPrefilter', '.cart-delete-confirmation-btn', function(e) {
    var url = $(this).data('action');
    $.ajaxPrefilter(function(options, originalOptions) {
      if (options.url.indexOf(url) === 0) {
        options.success = function(data) {
          updateCommission(data.basket);
          originalOptions.success(data);
        };
      }
    });
  });
  $('body').one('change.addPrefilterQuantity', '.quantity-form > .quantity', function() {
    var url = $(this).data('action');
    $.ajaxPrefilter(function(options, originalOptions) {
      if (options.url.indexOf(url) === 0) {
        options.success = function(data) {
          updateCommission(data);
          originalOptions.success(data);
        };
      }
    });
  });

  $('body').one('change.addPrefilterShippingMethod', '.shippingMethods', function() {
    var url = $(this).attr('data-actionUrl');
    $.ajaxPrefilter(function(options, originalOptions) {
      if (options.url.indexOf(url) === 0) {
        options.success = function(data) {
          if (!data.error) {
            updateCommission(data);
          }
          originalOptions.success(data);
        };
      }
    });
  });

  $('body').one('submit.addPrefilter', '.promo-code-form', function(data) {
    var $form = $('.promo-code-form');
    var url = $form.attr('action');
    $.ajaxPrefilter(function(options, originalOptions) {
      if (options.url.indexOf(url) === 0) {
        options.success = function(data) {
          if (!data.error) {
            updateCommission(data);
          }
          originalOptions.success(data);
        };
      }
    });
  });

  $('body').one('click.addPrefilterDeleteCoupon', '.delete-coupon-confirmation-btn', function(e) {
    var url = $(this).data('action');
    $.ajaxPrefilter(function(options, originalOptions) {
      if (options.url.indexOf(url) === 0) {
        options.success = function(data) {
          updateCommission(data);
          originalOptions.success(data);
        };
      }
    });
  });

  $('body').one('click.addPrefilterUpdateCartProduct', '.update-cart-product-global', function(e) {
    var url = $(this)
      .closest('.cart-and-ipay')
      .find('.update-cart-url')
      .val();
    $.ajaxPrefilter(function(options, originalOptions) {
      if (options.url.indexOf(url) === 0) {
        options.success = function(data) {
          updateCommission(data.cartModel);
          originalOptions.success(data);
        };
      }
    });
  });

    $('body').on('input', '#gift-target .notused-gift-message', function() {
        if ($(this).val().trim() === '') {
            $('.action-submit').prop('disabled', true).removeAttr('style');
        } else {
            $('.action-submit').prop('disabled', false).css({'cursor': 'pointer', 'pointer-events': 'initial'});
        }
    });
    $('body').on('click', '.action-submit[value="remove"]', function() {
        $('.action-submit').prop('disabled', true).removeAttr('style');
    });
});
