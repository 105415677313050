'use strict';

const $veil = $(`
  <div class="veil">
    <div class="underlay"></div>
    <div class="loader">
    </div>
  </div>
`);

/**
 * Show a spinner inside a given element
 * @param {element} $target - Element to block by the veil and spinner.
 *                            Pass body to block the whole page.
 */
function addSpinner($target) {
  if ($target.get(0).tagName === 'IMG') {
    $target.after($veil);
    $veil.css({ width: $target.width(), height: $target.height() });
    if ($target.parent().css('position') === 'static') {
      $target.parent().css('position', 'relative');
    }
  } else {
    if ($target.find('.veil').length > 0) {
      return;
    }
    $target.append($veil);
    if ($target.css('position') === 'static') {
      $target.parent().css('position', 'relative');
      $target.parent().addClass('veiled');
    }
    if ($target.get(0).tagName === 'BODY') {
      $veil.find('.spinner').css('position', 'fixed');
    }
  }
  $veil.click(function(e) {
    e.stopPropagation();
  });
}

/**
 * Remove existing spinner
 * @param  {element} $veil - jQuery pointer to the veil element
 */
function removeSpinner() {
  if ($veil.parent().hasClass('veiled')) {
    $veil.parent().css('position', '');
    $veil.parent().removeClass('veiled');
  }
  $veil.off('click');
  $veil.remove();
}

// page-level spinner:
$.spinner = function() {
  var Fn = function() {
    this.start = function() {
      addSpinner($('body'));
    };
    this.stop = removeSpinner;
  };
  return new Fn();
};

$.fn.spinner = $.spinner;
