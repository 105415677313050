'use strict';
const $ = (window.jQuery = window.$ = require('jquery'));

let Selector = {
    checkoutFlow: '#checkout-flow',
    cartDot: '#cart-dot .red-dot',
    shippingDot: '#shipping-dot .red-dot',
    paymentDot: '#payment-dot .red-dot',
    checkoutPage: '#checkout-main',
    shippingButton: '.submit-shipping'
};

let bindEvent = () => {
    let stage = $(Selector.checkoutPage).data().checkoutStage;
    checkStage(stage);
};

let checkStage = (stage) => {
    if (stage === 'shipping') {
        $(Selector.shippingDot).addClass('active');
    } else if (stage === 'payment') {
        $(Selector.shippingDot).addClass('active');
        $(Selector.paymentDot).addClass('active');

        $('#billingCountry option:first').prop('selected', true);

        var isMultiShip = $('#checkout-main').hasClass('multi-ship');
        var formSelector = isMultiShip ? '.multi-shipping .active form' : '.single-shipping .shipping-form';
        var form = $(formSelector);
        var shippingFormData = form.serialize();

        $('#billingCountry').on('change', function() {
            $.ajax({
                url: form.attr('action'),
                type: 'post',
                data: shippingFormData,
                success: function (data) {
                    var fiscalCodeUsageObj = data.fiscalCodeUsageObj;
                    var isCFMandatoryB2C = fiscalCodeUsageObj.isCFMandatoryB2C;

                    var fiscalCodeFormGroup = $('.dwfrm_billing_addressFields_codiceFiscale');
                    var fiscalCodeFieldDescritpion = $('.dwfrm_billing_addressFields_codiceFiscale .description');

                    if($('#billingCountry').val() != 'IT') {
                        fiscalCodeFormGroup.css('display', 'none');
                        $(".is-company-private .use-shipping-as-billing").removeClass('col-sm-4').addClass('col-sm-6');
                    } else {
                        fiscalCodeFormGroup.css('display', 'block');
                        $(".is-company-private .use-shipping-as-billing").removeClass('col-sm-6').addClass('col-sm-4');
                    }

                    if (isCFMandatoryB2C === true) {
                        fiscalCodeFormGroup.addClass('required');
                        fiscalCodeFieldDescritpion.css('display', 'block');
                    } else {
                        if ($('#billingCountry').val() == 'IT' && (fiscalCodeUsageObj.bonusLineItemsValue > 0 || fiscalCodeUsageObj.shippingCostValue > 0 || fiscalCodeUsageObj.isAncillary === true  || fiscalCodeUsageObj.isAncillaryCategory === true)) {
                            fiscalCodeFormGroup.addClass('required');
                            fiscalCodeFieldDescritpion.css('display', 'block');
                        } else {
                            fiscalCodeFormGroup.removeClass('required');
                            fiscalCodeFieldDescritpion.css('display', 'none');
                        }
                    }
                },
            });
        });
    } else if (stage === 'placeOrder') {
        $(Selector.shippingDot).addClass('active');
        $(Selector.paymentDot).addClass('active');
    }
};

module.exports = function() {
    //check if we are on the checkout page
    if (document.body.querySelector('#checkout-main') !== null) bindEvent();
};
