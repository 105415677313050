'use strict';
const $ = (window.jQuery = window.$ = require('jquery'));

let Selector = {
  addToCartButton: '#js-add-to-cart',
  bundle: '#js-add-to-cart-bundle'
};

let bindEvent = () => {
  let bundleHeight = $(Selector.bundle).height();
  $('.main-footer').css('padding-bottom', bundleHeight + 24);

  if ($(window).width() >= 992 && $('.page').data().action === 'Product-Show') {
    toggleBundle();
  }
};

let toggleBundle = () => {
  $(window).scroll((e) => {
    let top_of_element = $(Selector.addToCartButton).offset().top;
    let bottom_of_element = $(Selector.addToCartButton).offset().top + $(Selector.addToCartButton).outerHeight();
    let bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
    let top_of_screen = $(window).scrollTop();
    let isVisible = bottom_of_screen > top_of_element && top_of_screen < bottom_of_element;

    isVisible ? $(Selector.bundle).fadeOut() : $(Selector.bundle).fadeIn();
  });
};

module.exports = function() {
  if ($(Selector.bundle).length) {
    console.log('Product bundle imported');
    bindEvent();
  }
};
