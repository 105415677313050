'use strict';
const $ = (window.jQuery = window.$ = require('jquery'));

let Selector = {
  component: '.js-fast-paste',
  input: '.js-input',
  inputTrigger: '.js-fast-paste-trigger',
  fakeInputOne: '.js-fake-code1',
  fakeInputTwo: '.js-fake-code2',
  fakeInputThree: '.js-fake-code3'
};

let bindEvent = () => {
  console.log('Fast paste activeted');

  $(Selector.input).on('focus', (e) => {
    $(e.currentTarget).attr('placeholder', '');
  });

  $(Selector.input).on('blur', (e) => {
    if ($(e.currentTarget).hasClass('js-fast-paste-trigger')) {
      $(e.currentTarget).attr('placeholder', '000');
    } else {
      $(e.currentTarget).attr('placeholder', '0000');
    }
  });

  $(`${Selector.fakeInputOne}, ${Selector.fakeInputTwo}, ${Selector.fakeInputThree}`).on('keyup', (e) => {
    let value = $(e.currentTarget).val();
    if (value !== value.toUpperCase()) {
      $(e.currentTarget).val(value.toUpperCase());
    }
  });
};

module.exports = function() {
  if ($(Selector.component).length) {
    bindEvent();
  }
};
