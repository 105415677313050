'use strict';

const { last } = require('underscore');

const $ = (window.jQuery = window.$ = require('jquery'));

var Selector = {
  hideOnScroll: '.js-scroll-hide',
  logo: '.js-logo',
  navbar: 'nav[role="navigation"]',
  header: 'nav[role="navigation"]>.header',
  mainContent: '#maincontent'
};

function scrollAnimation() {
  let lastScroll = 0;
  let headerHeight = $(Selector.navbar).height();

  $(window).scroll(function(e) {
    let scrollY = $(window).scrollTop();

    if (lastScroll > scrollY) {
      //if the user is going up
      if ($(window).scrollTop() <= 200) {
        $(Selector.navbar).removeClass('sticky-show');
        $(".promo-banner-container").removeClass('sticky-show'); 

        setTimeout(() => {
          $(Selector.navbar).removeClass('sticky-transition');
        $(".promo-banner-container").removeClass('sticky-transition'); 

        }, 500);
      }

      if ($(window).scrollTop() <= 190) {
        $(Selector.navbar).removeClass('sticky');
        $(".promo-banner-container").removeClass('sticky'); 
        $(Selector.mainContent).css('padding-top', '0');
      }
    } else {
      //hide elements when the page header is not visible
      if ($(window).scrollTop() >= headerHeight - 1) {
        $(Selector.navbar).addClass('sticky');
        $(".promo-banner-container").addClass('sticky'); 
        $(Selector.mainContent).css('padding-top', '189px');

        setTimeout(() => {
          $(Selector.navbar).addClass('sticky-transition');
          $(".promo-banner-container").addClass('sticky-transition'); 
          $(Selector.navbar).addClass('sticky-show');
          $(".promo-banner-container").addClass('sticky-show'); 
        }, 500);
      }
    }

    lastScroll = scrollY;
  });
}

function bindEvent() {
  if ($(window).width() >= 992 && !$(Selector.header).hasClass('header-nomenu')) {
    scrollAnimation();
  }
}

module.exports = function() {
  bindEvent();
};

$(function() {
  $('.header-banner .close').on('click', function() {
    $('header nav').addClass('no-banner');
  });
});
