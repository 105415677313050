'use strict';
const $ = (window.jQuery = window.$ = require('jquery'));

let Selector = {
  qtyInput: '.js-quantity',
  plus: '.js-qty-plus',
  minus: '.js-qty-minus'
};

let Data = {
  increase: 'increase',
  decrease: 'decrease'
};

let bindEvent = () => {
  $(Selector.plus).on('click', (e) => {
    e.preventDefault();
    updateQty(Data.increase, $('#' + e.currentTarget.dataset.target));
  });

  $(Selector.minus).on('click', (e) => {
    e.preventDefault();
    updateQty(Data.decrease, $('#' + e.currentTarget.dataset.target));
  });
};

let updateQty = (updateStatus, target) => {
  let targetValue = parseInt(target.val());

  if (updateStatus === Data.increase) {
    target.val(targetValue + 1).trigger('change');
  } else if (updateStatus === Data.decrease) {
    if (targetValue > 1) {
      target.val(targetValue - 1).trigger('change');
    }
  }
};

module.exports = function() {
  if (document.body.querySelector(Selector.qtyInput)) {
    console.log('custom quantity imported');
    bindEvent();
  }
};
