'use strict';
const $ = (window.jQuery = window.$ = require('jquery'));

let Selector = {
  page: '.js-bookingdetail-page',
  resendEmail: '.js-resend-email',
  btnEmail: '.js-booking-email-button'
};

let bindEvent = () => {
  console.log('Booking details online');

  $(Selector.resendEmail).on('submit', (evt) => {
    let $btn = $(Selector.btnEmail);
    evt.preventDefault();
    let $form = $(evt.currentTarget);
    let serialNumber = $form.find('input[name="serial-number"]').val();
    let url = $form.attr('action');

    $.post(url, { serialNumber: serialNumber }).done((data) => {
      if (data.success) {
        let successIcon = `<img src="${$('.booking-messages').data().successIcon}" alt="" />`;
        $('.booking-messages').append(
          '<div class="alert alert-success booking-alert text-center" role="alert">' +
            successIcon +
            '<p class="mb-2" data-color="success">Invio completato!</p><p class="mb-0">' +
            data.message +
            '</p></div>'
        );
        $btn.attr('disabled', 'disabled');
      } else {
        $('.booking-messages').append('<div class="alert alert-danger booking-alert text-center"' + ' role="alert"><p class="mb-0">' + data.message + '</p></div>');
      }

      setTimeout(function() {
        $('.booking-alert').remove();
      }, 5000);
    });
  });
};

module.exports = function() {
  if ($(Selector.page).length > 0) bindEvent();
};
