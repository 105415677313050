'use strict';
const $ = (window.jQuery = window.$ = require('jquery'));

let Selector = {
  overlay: '.js-overlay',
  overlayTrigger: '.js-overlay-trigger',
  overlayTarget: '.js-overlay-target'
};

let bindEvent = () => {
  console.log('Overlay imported');
  //check if there are overlays in the page
  $('body').on('click', Selector.overlayTrigger, (e) => {
    e.preventDefault();
    toggleTarget(e.currentTarget);
  });
};

let toggleTarget = (trigger) => {
  let target = $(trigger).data().target;
  if ($(target).hasClass('out')) {
    $(target).addClass('shown');
    $(target).removeClass('out');
    $('.modal-background').show();
  } else {
    $(target).removeClass('shown');
    $(target).addClass('out');
    $('.modal-background').hide();
  }
};

module.exports = function() {
  bindEvent();
};
