'use strict';

let Selector = {
  passwordToggleButton: '.js-pwd-toggler',
  passwordInput: '.js-password-input'
};

let bindEvent = () => {
  $(Selector.passwordToggleButton).on('click', (e) => {
    togglePwd(e);
  });

  $(Selector.passwordToggleButton).on('keydown', (e) => {
    if (e.keyCode === 13) togglePwd(e);
  });
};

let togglePwd = (event) => {
  event.preventDefault();
  let catalyst = event.currentTarget;
  let target = $(catalyst).data().target;
  let label = $(catalyst).find('.js-show-label');

  if ($(target).attr('type') === 'password') {
    $(target).attr('type', 'text');
    $(label).text($(catalyst).data().hideLabel);
  } else {
    $(target).attr('type', 'password');
    $(label).text($(catalyst).data().showLabel);
  }
};

module.exports = function() {
  if ($(Selector.passwordToggleButton)) bindEvent();
};
