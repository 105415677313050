'use strict';

/**
 * Validate whole form. Requires `this` to be set to form object
 * @param {jQuery.event} event - Event to be canceled if form is invalid.
 * @returns {boolean} - Flag to indicate if form is valid
 */
function validateForm(event) {
  var valid = true;
  if (this.checkValidity && !this.checkValidity()) {
    // safari
    valid = false;
    if (event) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
    }
    $(this)
      .find('input, select')
      .each(function() {
        if (!this.validity.valid) {
          $(this).trigger('invalid', this.validity);
        }
      });
  }
  return valid;
}

/**
 * Remove all validation. Should be called every time before revalidating form
 * @param {element} form - Form to be cleared
 * @returns {void}
 */
function clearForm(form) {
  $(form)
    .find('.form-control.is-invalid')
    .removeClass('is-invalid');
}

module.exports = {
  invalid: function() {
    $('form input, form select').on('invalid', function(e) {
      e.preventDefault();
      this.setCustomValidity('');
      if (!this.validity.valid) {
        var validationMessage = this.validationMessage;
        if(this.id === 'registration-form-fname' || this.id === 'registration-form-lname'){
          validationMessage = 'Inserire solo caratteri alfabetici';
        }
        $(this).addClass('is-invalid');
          if (this.validity.patternMismatch && $(this).data('pattern-mismatch')) {
            validationMessage = $(this).data('pattern-mismatch');
          }
          if ((this.validity.rangeOverflow || this.validity.rangeUnderflow) && $(this).data('range-error')) {
            validationMessage = $(this).data('range-error');
          }
          if ((this.validity.tooLong || this.validity.tooShort) && $(this).data('range-error')) {
            validationMessage = $(this).data('range-error');
          }
          if (this.validity.valueMissing && $(this).data('missing-error')) {
            validationMessage = $(this).data('missing-error');
          }
          $(this)
          .parents('.form-group')
          .find('.invalid-feedback')
          .text(validationMessage);
      }
    });
  },

  submit: function() {
    $('form').on('submit', function(e) {
      var validateresp = validateForm.call(this, e);
      var captcha = $(this).find('.g-recaptcha').length ? $(this).find('.g-recaptcha') : $(this).find('#g-recaptcha-init');

      if (captcha.length && validateresp) {
        var gTokenClient = grecaptcha.getResponse();
        var submitidreq = $(this).find("input[name=idsubmit]").data("id");
        var action = $('.grec-info').data('uri');

        if (gTokenClient == '') {
          captcha.addClass('is-invalid');
          captcha.siblings('.invalid-captcha').text('Bisogna accettare il captcha!');
          console.log('Non è stato premuto il captcha!!');
          e.preventDefault();
          return;
        } else {
          var captchaDone = false;
          $.ajax({
            url: action,
            method: 'GET',
            async: false,
            data: {
              gTokenClient: gTokenClient,
              submitidreq: submitidreq
            },
            success: function(data) {
              if (data.success) {
                console.log("Accepted Cap");
                captchaDone = true;
                var button = $(this).find(':submit');
                $.spinner().start();
                button.attr('disabled', true);
                $(e.target).attr('action', data.urlResponse);
              } else {
                captcha.addClass('is-invalid');
                captcha.siblings('.invalid-feedback').text('ERROR Captcha: Captcha scaduto! Riavviare');
                console.log(data.errorMessage);
                e.preventDefault();
                return;
              }
            },
            error: function(e) {
              console.log('error captcha!');
            }
          });

          if(captchaDone){
            var button = $(this).find(':submit');
            $.spinner().start();
            button.attr('disabled', true);
          }
          return captchaDone;
        }
      } else {
        if(validateresp){
          var button = $(this).find(':submit');
          $.spinner().start();
          button.attr('disabled', true);
        }
        return validateresp;
      }
    });
  },
  buttonClick: function() {
    $('form button[type="submit"], form input[type="submit"]').on('click', function() {
      // clear all errors when trying to submit the form
      clearForm($(this).parents('form'));
    });
  },

  functions: {
    validateForm: function(form, event) {
      validateForm.call($(form), event || null);
    },
    clearForm: clearForm
  }
};
