'use strict';
const $ = (window.jQuery = window.$ = require('jquery'));

let Selector = {
  accordion: '.js-accordion',
  accordionTrigger: '.js-accordion-trigger',
  accordionTarget: '.js-accordion-target'
};

let bindEvent = () => {
  console.log('Accordion online');
  $('body').on('click', Selector.accordionTrigger, (e) => {
    let target = '#' + $(e.currentTarget).data().controls;
    let parent = $(e.currentTarget).closest(Selector.accordion);
    toggleTarget($(target), $(e.currentTarget), parent);
  });
};

let toggleTarget = (target, trigger, parent) => {
  if (target.hasClass('active')) {
    if (parent.data().smooth === true) {
      target.slideUp();
    } else {
      target.removeClass('show');
      setTimeout(() => {
        target.removeClass('setHeight');
      }, 300);
    }
    parent.removeClass('expanded');
    target.removeClass('active');
    trigger.removeClass('on');
  } else {
    if (parent.data().smooth === true) {
      target.slideDown();
    } else {
      target.addClass('setHeight');
      target.addClass('show');
    }
    parent.addClass('expanded');
    target.addClass('active');
    trigger.addClass('on');
  }
};

module.exports = function() {
  bindEvent();
};
