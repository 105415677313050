'use strict';
const $ = (window.jQuery = window.$ = require('jquery'));

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
const handlePostCartAdd = (response) => {
  $('.minicart').trigger('count:update', response);
  var messageType = response.error ? 'alert-danger' : 'alert-success';
  if ($('.add-to-cart-messages').length === 0) {
    $('body').append('<div class="add-to-cart-messages"></div>');
  }

  $('.add-to-cart-messages').append('<div class="alert ' + messageType + ' add-to-basket-alert text-center" role="alert">' + response.message + '</div>');

  setTimeout(function() {
    $('.add-to-basket-alert').remove();
  }, 5000);
};

const quickBuy = () => {
  $(document).on('click', 'button.quick-buy-btn', function() {
    var addToCartUrl;
    var pid;

    $('body').trigger('product:beforeAddToCart', this);

    pid = $(this).data('pid');
    pid += '_D'; //set variant
    var productContainer = $(this).closest('.quick-product-detail');

    addToCartUrl = $(productContainer)
      .find('.add-to-cart-url')
      .val();

    var form = {
      pid: pid,
      pidsObj: {},
      childProducts: {},
      quantity: 1
    };

    $(this).trigger('updateAddToCartFormData', form);
    if (addToCartUrl) {
      $.ajax({
        url: addToCartUrl,
        method: 'POST',
        data: form,
        success: function(data) {
          handlePostCartAdd(data);
          $('body').trigger('product:afterAddToCart', data);
          $.spinner().stop();
        },
        error: function() {
          $.spinner().stop();
        }
      });
    }
  });
};

let bindEvent = () => {
  quickBuy();
};

module.exports = function() {
  bindEvent();
};
