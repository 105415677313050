'use strict';

const $ = (window.jQuery = window.$ = require('jquery'));

/* Standard Slider */
var standardSlider = function() {
  if ($('.standard-slider').length > 0) {
    if ($('.standard-slider .slick-slide').length == 0) {
      $('.standard-slider').each(function() {
        var slidesContainer = $(this);

        var $currentSlide = $(this)
          .parent()
          .find('.content-number-slide .current-number');
        var $totalSlide = $(this)
          .parent()
          .find('.content-number-slide .total-number');
        var $slickElement = $(this);

        $slickElement.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide) {
          var i = 0;

          if (slick.slideCount > 1) {
            i = (currentSlide ? currentSlide : 0) + 1;
            $currentSlide.text('0' + i);
          } else {
            i = 0;
            $currentSlide.closest('.content-number-slide').hide();
          }

          //$totalSlide.text(slick.slideCount);
        });

        slidesContainer.slick({
          infinite: true,
          autoplay: false,
          focusOnSelect: true,
          centerMode: false,
          autoplaySpeed: 3000,
          speed: 500,
          slidesToScroll: 1,
          slidesToShow: 1,
          variableWidth: false,
          dots: true
        });
      });
    }
  }
};

/* Simple Slider */
var simpleSlider = function() {
  if ($('.simple-slider').length > 0) {
    if ($('.simple-slider .slick-slide').length == 0) {
      $('.simple-slider').each(function() {
        var slidesContainer = $(this);

        slidesContainer.slick({
          infinite: false,
          autoplay: false,
          focusOnSelect: true,
          centerMode: false,
          autoplaySpeed: 3000,
          speed: 500,
          slidesToScroll: 1,
          slidesToShow: 4,
          variableWidth: false,
          dots: true,
          prevArrow: false,
          nextArrow: false,
          responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 543,
              settings: {
                slidesToShow: 1
              }
            }
          ]
        });
      });
    }
  }
};

/* Selected Boxes Slider */
var selectedBoxesSlider = function() {
  if ($('.selected-boxes-slider').length > 0) {
    if ($('.selected-boxes-slider .slick-slide').length == 0) {
      $('.selected-boxes-slider').each(function() {
        var slidesContainer = $(this);

        slidesContainer.slick({
          infinite: false,
          autoplay: false,
          focusOnSelect: true,
          centerMode: false,
          autoplaySpeed: 3000,
          speed: 500,
          slidesToScroll: 1,
          slidesToShow: 3,
          variableWidth: false,
          dots: true,
          prevArrow: false,
          nextArrow: false,
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 1
              }
            }
          ]
        });
      });
    }
  }
};

var recaptchaManualInit = function() {
  if($('#g-recaptcha-init').length){
    var sitekey = $('.grec-info').data('skey');
    grecaptcha.render('g-recaptcha-init', {"sitekey" : sitekey});
  }
};

$(document).ready(function() {
  standardSlider();
  simpleSlider();
  selectedBoxesSlider();
  // eslint-disable-next-line sonarjs/no-use-of-empty-return-value


  // USER TYPE
  $(document).on('click', '.content-user-type .user-type', function() {
    $(this)
      .siblings()
      .removeClass('active');
    $(this).addClass('active');

    if ($(this).hasClass('user-type-agency')) {
      $(this)
        .closest('.login-form-nav')
        .find('.content-for-agency')
        .show();
      $(this)
        .closest('.login-form-nav')
        .addClass('login-form-nav--agency');
    } else {
      $(this)
        .closest('.login-form-nav')
        .find('.content-for-agency')
        .hide();
      $(this)
        .closest('.login-form-nav')
        .removeClass('login-form-nav--agency');
    }
  });

  // SEARCH DESKTOP
  $('.link-search').on('click', function() {
    let searchMain = $('.content-search-space');
    let searchAgency = $('.content-search-agency');
    $('header .search-desktop .site-search').fadeIn('300');
    $('body').addClass('body-no-scroll');
    if ($(this).hasClass('link-changeAgency')) {
      searchMain.hide();
      searchAgency.show();
      $('header .search-desktop .site-search .search-field').focus();
    } else {
      searchMain.show();
      searchAgency.hide();
      $('header .search-desktop .site-search .search-field').focus();
    }
  });
  $('header .search-desktop .site-search .site-search-close').on('click', function() {
    $('header .search-desktop .site-search').fadeOut('300');
    $('body').removeClass('body-no-scroll');
  });
});

window.onloadCallback = function() {
  recaptchaManualInit();
};

jQuery.event.special.touchstart = {
    setup: function( _, ns, handle ) {
        this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.touchmove = {
    setup: function( _, ns, handle ) {
        this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.wheel = {
    setup: function( _, ns, handle ){
        this.addEventListener("wheel", handle, { passive: true });
    }
};
jQuery.event.special.mousewheel = {
    setup: function( _, ns, handle ){
        this.addEventListener("mousewheel", handle, { passive: true });
    }
};