/* eslint-disable no-undef */
'use strict';
// const $ = (window.jQuery = window.$ = require('jquery'));

const TYPES = ['INFO', 'WARNING', 'ERROR', 'SUCCESS', 'CONFIRM'];

function showNotification(msg, type, cb) {
  if (typeof type === 'number') {
    type = TYPES[type];
  }

  const notificationEl = $('#notification-modal');

  // alert(`[${type || 'INFO'}] ${msg}`);
  notificationEl.addClass(`notify-${type.toLowerCase()}`).one('hidden.bs.modal', function() {
    notificationEl.removeClass(`notify-${type.toLowerCase()}`);
  });
  notificationEl.find('.modal-body').html(msg);
  if (type == 'CONFIRM') {
    notificationEl.find('.modal-footer [data-notification-action=ok]').one('click', function() {
      cb && cb(true);
    });
    notificationEl.find('.modal-footer [data-notification-action=cancel]').one('click', function() {
      cb && cb(false);
    });
  }
  notificationEl.modal('show');
}

module.exports = function() {
  $.ajaxSetup(
    {
      beforeSend: function() {
        $.spinner && $.spinner().start();
      },
      error: function(xhr, status) {
        // alert(`error ${status}`);
        //
        if (typeof xhr !== 'undefined') {
          let response;
          try {
            response = xhr && 'responseText' in xhr && xhr.responseText;
            response = JSON.parse(response);
          } catch (e) {
            // silently fail
          }
          if (response && typeof response !== 'string' && response.notification) {
            let noty = response.notification;
            if (noty.type == 4 || noty.type == 'CONFIRM') {
              // SKIP configurmation noty. It will be cought in each ajax call
              console.warn('skip global notification managemnt because it is CONFIRM');
            } else {
              showNotification(noty.message, noty.type);
            }
          }
        }
      },
      success: function(response) {
        if (typeof response !== 'string' && response.notification) {
          let noty = response.notification;
          if (noty.type == 4 || noty.type == 'CONFIRM') {
            // SKIP configurmation noty. It will be cought in each ajax call
            console.warn('skip global notification managemnt because it is CONFIRM');
          } else if (noty.reload) {
            window.location.reload();
          } else if (noty.redirectUrl) {
            window.location.href = noty.redirectUrl;
          } else {
            showNotification(noty.message, noty.type);
          }
        }
      },
      complete: function() {
        $.spinner && $.spinner().stop();
      }
    },
    null,
    true
  );

  $.notification = showNotification;

  if (window.NotificationObject) {
    $.notification(window.NotificationObject.message, window.NotificationObject.type, null, window.NotificationObject.reload);
  }
};
