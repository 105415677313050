'use strict';
const $ = (window.jQuery = window.$ = require('jquery'));

let Selector = {
  carouselClass: '.js-accessories'
};

let bindEvent = () => {
  console.log('Carousel online');
  $(Selector.carouselClass).slick({
    infinite: true,
    autoplay: false,
    focusOnSelect: true,
    centerMode: false,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 3,
    variableWidth: false,
    dots: false,
    prevArrow: false,
    nextArrow: false
  });
};

module.exports = function() {
  //var accessories = document.body.querySelector('.accessories');
  //if (accessories) bindEvent();
};
