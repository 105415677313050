'use strict';
const $ = (window.jQuery = window.$ = require('jquery'));

let Selector = {
  page: '#js-target-page',
  errorModal: '#js-error-modal'
};

let bindEvent = () => {
  console.log('Product registration js ready');

  if ($(Selector.page).data().error) {
    $(Selector.errorModal).modal('show');
  }
};

module.exports = function() {
  if ($(Selector.page).length) {
    bindEvent();
  }
};
