'use strict';
const $ = (window.jQuery = window.$ = require('jquery'));

let Selector = {
  form: '.js-variation-form',
  radioOption: '.js-radio-option'
};

function execute(form, force, cb) {
  let postData = form.serializeArray();
  postData.push({ name: 'force', value: !!force });
  $.ajax({
    method: form.attr('method'),
    url: form.attr('action'),
    data: postData,
    success: function(response) {
      cb(true, response);
    },
    error: function(xhr, a, b) {
      if (xhr.status == 409) {
        let response = xhr.responseJSON;
        if (response.notification) {
          $.notification(response.notification.message, response.notification.type, function(success) {
            if (success) {
              execute(form, true, cb);
            } else {
              cb(false, response);
            }
          });
          return;
        }
      }
    }
  });
}

let bindEvent = () => {
  $(Selector.radioOption).on('change', (e) => {
    let thisForm = $(e.currentTarget).closest(Selector.form);
    // thisForm.submit();
    // thisForm.serializeArray();
    execute(thisForm, false, function(success, response) {
      if (!success) {
        // re-select previous check
        let radio = thisForm.find('[name=pid]').filter(function(i, el) {
          return el !== e.currentTarget;
        });
        radio.attr('checked', 'checked');
        $(e.currentTarget).removeAttr('checked');
        radio[0].checked = true;
        e.currentTarget.checked = false;
      } else {
        location.reload();

        var formatType = $(e.currentTarget).attr('data-attr-value');

        $('.product-recap, .product-card').each(function() {
          var itemName, isProductCard;

          if ($(this).hasClass('product-recap')) {
            itemName = $(this).find('.line-item-description').text().toLowerCase();
            isProductCard = false;
          } else {
            itemName = $(this).find('.item-name').text().toLowerCase();
            isProductCard = true;
          }

          if (!(itemName.includes('gift') && itemName.includes('card'))) {
            $(this).find('.line-item-attributes.Box').text(formatType);

            if (isProductCard) {
              $(this).find('.variation-radio input[name="pid"]').prop('checked', function() {
                return $(this).data('attr-value') === formatType;
              });
            }
          }
        });

        $('.variation-radio input[name="pid"]').prop('disabled', true);
      }
    });
  });
};

module.exports = function() {
  if ($('.cart-page').length) bindEvent();
};
