'use strict';
const $ = (window.jQuery = window.$ = require('jquery'));

let Selector = {
  map: '#editorial-map',
  locations: '#pin-list'
};

let bindEvent = () => {
  console.log('Editorial Map active');
  renderMap();
};

let renderMap = () => {
  let map;
  let zoomLevel = $(Selector.map).data('zoom') || 8;
  let centerCoordinates = {};
  let pinList = document.getElementById('pin-list');
  let mapJSON = $(Selector.map).data('mapjson');

  $.getJSON(mapJSON, (response) => {
    //set the center point based on the last pin position
    let centerPoint = response.features[response.features.length - 1].geometry.coordinates;
    centerCoordinates.lat = parseFloat(centerPoint[1]);
    centerCoordinates.lng = parseFloat(centerPoint[0]);
  }).done(() => {
    map = new window.google.maps.Map(document.getElementById('editorial-map'), {
      center: centerCoordinates,
      mapTypeControl: false,
      overviewMapControl: false,
      panControl: false,
      scaleControl: false,
      streetViewControl: false,
      scrollwheel: false,
      minZoom: 1,
      maxZoom: 18,
      zoom: zoomLevel,
      zoomControl: true,
      gestureHandling: 'greedy'
    });

    map.data.loadGeoJson(mapJSON, null, (features) => {
      let indexList = 0;

      features.forEach((feature) => {
        if (feature.getGeometry().getType() === 'Point') {
          feature.setProperty('idList', indexList);
          indexList++;
          let li = document.createElement('li');
          li.innerText = feature.getProperty('name');
          li.classList.add('h4', 'mb-0', 'serif');

          li.addEventListener('click', (event) => {
            map.data.revertStyle();
            map.data.overrideStyle(feature, { icon: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png' });
            feature.setProperty('selected', true);

            for (let child of pinList.children) {
              child.classList.remove('selected');
            }
            li.classList.add('selected');
          });

          pinList.appendChild(li);
        }
      });
    });

    map.data.addListener('click', function(event) {
      if (typeof event.feature.getProperty('idList') !== 'undefined') {
        map.data.revertStyle();
        map.data.overrideStyle(event.feature, { icon: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png' });
        event.feature.setProperty('selected', true);
        for (let child of pinList.children) {
          child.classList.remove('selected');
        }
        pinList.children[event.feature.getProperty('idList')].classList.add('selected');
      }
    });
  });
};

module.exports = function() {
  if ($(Selector.map).length) {
    bindEvent();
  }
};
