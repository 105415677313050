const $ = (window.jQuery = window.$ = require('jquery'));

(function() {
  const _ajaxSetup = $.ajaxSetup;
  const _successes = [];
  const _errors = [];
  const _completes = [];
  const _beforeSends = [];

  $.ajaxSetup = function(target, settings, global) {
    let opts = settings ? settings : target;

    if (global) {
      if (opts.success) {
        _successes.push(opts.success);
        delete opts.success;
      }
      if (opts.error) {
        _errors.push(opts.error);
        delete opts.error;
      }
      if (opts.beforeSend) {
        _beforeSends.push(opts.beforeSend);
        delete opts.beforeSend;
      }
      if (opts.complete) {
        _completes.push(opts.complete);
        delete opts.complete;
      }
    }

    let succ = opts.success;
    let err = opts.error;
    let bef = opts.beforeSend;
    let compl = opts.complete;

    opts.success = function(data, txtStatus, xhr) {
      for (let fn of _successes) {
        fn(data, txtStatus, xhr);
      }
      if (succ) {
        succ(data, txtStatus, xhr);
      }
    };
    opts.error = function(xhr, txtStatus, txtError) {
      for (let fn of _errors) {
        fn(xhr, txtStatus, txtError);
      }
      if (err) {
        err(xhr, txtStatus, txtError);
      }
    };
    opts.beforeSend = function(xhr, sets) {
      for (let fn of _beforeSends) {
        fn(xhr, sets);
      }
      if (bef) {
        bef(xhr, sets);
      }
    };
    opts.complete = function(xhr, txtStatus) {
      for (let fn of _completes) {
        fn(xhr, txtStatus);
      }
      if (compl) {
        compl(xhr, txtStatus);
      }
    };

    return _ajaxSetup(target, settings ? opts : settings);
  };
})();
