'use strict';
const $ = (window.jQuery = window.$ = require('jquery'));

const Selector = {
  datePicker: '.js-duet-date-picker',
  input: '.duet-date__input',
  toggle: '.duet-date__toggle'
};

const bindEvent = () => {
  const currentLang = $(Selector.datePicker).data('language');
  const dayNames = getLocalDayslabel(currentLang);
  const monthNames = getLocalMonthsLabel(currentLang);
  const monthNamesShort = getLocalShortMonthsLabel(currentLang);
  const locale = getLocale(currentLang);

  const picker = document.querySelector('duet-date-picker');
  const DATE_FORMAT = /^(\d{1,2})\.(\d{1,2})\.(\d{4})$/;

  picker.dateAdapter = {
    parse(value = '', createDate) {
      const matches = value.match(DATE_FORMAT);

      if (matches) {
        return createDate(matches[3], matches[2], matches[1]);
      }
    },
    format(date) {
      let day = date.getDate() <= 9 ? '0' + date.getDate() : date.getDate();
      let month = date.getMonth() + 1 <= 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
      let year = date.getFullYear();

      return `${day}/${month}/${year}`;
    }
  };

  picker.localization = {
    buttonLabel: 'Select date from calendar',
    placeholder: 'gg/mm/aaaa',
    selectedDateMessage: 'Date selected',
    prevMonthLabel: 'Previous month',
    nextMonthLabel: 'Next month',
    monthSelectLabel: 'Select month',
    yearSelectLabel: 'Select year',
    closeLabel: 'Close calendar',
    keyboardInstruction: 'Use the keyboard to navigateinside the calendar',
    calendarHeading: 'Select date',
    dayNames: dayNames,
    monthNames: monthNames,
    monthNamesShort: monthNamesShort,
    locale: locale
  };

  setTimeout(() => {
    const date_input = $(Selector.input);

    date_input.on('click', (event) => {
      event.preventDefault();
      $(Selector.toggle).trigger('click');
    });
    date_input.on('keydown', (event) => {
      event.preventDefault();
    });
  }, 1000);
};

const getLocalDayslabel = (currentLang) => {
  if (currentLang === 'it') {
    return ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'];
  } else if (currentLang === 'en') {
    return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  }
};

const getLocalMonthsLabel = (currentLang) => {
  if (currentLang === 'it') {
    return ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novemvre', 'Dicembre'];
  } else if (currentLang === 'en') {
    return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  }
};

const getLocalShortMonthsLabel = (currentLang) => {
  if (currentLang === 'it') {
    return ['GEN', 'FEB', 'MAR', 'APR', 'MAG', 'GIU', 'LUG', 'AGO', 'SET', 'OTT', 'NOV', 'DIC'];
  } else if (currentLang === 'en') {
    return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  }
};

const getLocale = (currentLang) => {
  if (currentLang === 'it') {
    return 'it-IT';
  } else if (currentLang === 'en') {
    return 'en-EN';
  }
};

module.exports = function() {
  if ($(Selector.datePicker).length > 0) {
    console.log('Duet datepicker imported');
    bindEvent();
  }
};
