'use strict';
const $ = (window.jQuery = window.$ = require('jquery'));

let Selector = {
  textSummary: '.js-text-summary',
  summaryTrigger: '.js-summary-trigger'
};

let bindEvent = () => {
  console.log('Text summary online');

  $('body').on('click', Selector.summaryTrigger, (e) => {
    $(Selector.textSummary).toggleClass('summerized');
  });
};

module.exports = () => {
  if ($(Selector.textSummary).length > 0) {
    bindEvent();
  }
};
