const $ = (window.jQuery = window.$ = require('jquery'));

module.exports = {
  init: function() {
    let $form = $('#product-registration-form');
    if ($form.length <= 0) {
      return;
    }
    let productCode = $form.find('[name=productCode]');
    let $fake1 = $form.find('#fakeProductcode1');
    let $fake2 = $form.find('#fakeProductcode2');
    let $fake3 = $form.find('#fakeProductcode3');

    $form.on('submit', function(e) {
      let str = [$fake1.val(), $fake2.val(), $fake3.val()];
      productCode.val(str.join('-'));
    });
  }
};
