const $ = (window.jQuery = window.$ = require('jquery'));
const processInclude = require('BASE/util');

const $mainMenu = $('.main-menu');

processInclude(require('BASE/components/menu'));

var clearSelection = function($dropdowns) {
  //var $dropdowns = $(element).find('.dropdown');
  $dropdowns
    .children('.dropdown-menu')
    .children('.top-category')
    .detach();
  // var $dropdownsShow = $dropdowns.filter('.show');
  $dropdowns.children('.nav-link').attr('aria-expanded', 'false');
  $dropdowns.children('.dropdown-menu').attr('aria-hidden', 'true');
  $dropdowns.removeClass('show');
  $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'false');
};

let highlightCurrentPage = () => {
  var fullLocationHref = location.href;
  var pathOnlyLocationHref = fullLocationHref.indexOf('?') > 0 ? fullLocationHref.substring(0, fullLocationHref.indexOf('?')) : fullLocationHref;

  let currentPageLink = $('.primary-menu a[href="' + pathOnlyLocationHref + '"].nav-link, .primary-menu a[href^="' + pathOnlyLocationHref + '?"].nav-link');
  currentPageLink.addClass('current-page').attr('aria-current', 'page');

  let dropdownMenu = currentPageLink.closest('.dropdown-menu');
  dropdownMenu
    .closest('.dropdown')
    .addClass('current-dropdown')
    .attr('aria-current', 'page');
};

module.exports = function() {
  var isDesktop = function(element) {
    return (
      $(element)
        .parents('.menu-toggleable-left')
        .css('position') !== 'fixed'
    );
  };

  $('.navbar-toggler')
    .off()
    .click(function(e) {
      e.preventDefault();
      const $this = $(this);
      $this.toggleClass('opened');

      if ($('body').hasClass('menu-open')) {
        $('body').removeClass('menu-open');
        $('body').removeClass('modal-open');
      } else {
        $('body').addClass('menu-open');
      }

      $mainMenu.toggleClass('in');
      $mainMenu.toggleClass('d-none');
      if ($mainMenu.hasClass('in')) {
        $mainMenu.attr('aria-hidden', 'false');

        $('.main-menu .nav.navbar-nav .nav-link')
          .first()
          .focus();
      } else {
        $mainMenu.attr('aria-hidden', 'true');
        clearSelection($mainMenu.find('.dropdown'));
      }
    });

  $('.dropdown:not(.disabled) [data-toggle="dropdown"]')
    .off('click')
    .on('click', function(e) {
      if (!isDesktop(this)) {
        // copy parent element into current UL
        var topCategory = $('<div class="nav-item dropdown-item top-category d-lg-none" role="button"></div>');
        var link = $(this)
          .clone()
          .removeClass('dropdown-toggle')
          .removeAttr('data-toggle')
          .removeAttr('aria-expanded')
          .attr('aria-haspopup', 'false');
        topCategory.append(link);
        $(this)
          .parent()
          .children('.dropdown-menu')
          .prepend(topCategory)
          .attr('aria-hidden', 'false');
        // copy navigation menu into view
        $(this)
          .parent()
          .addClass('show');
        $(this).attr('aria-expanded', 'true');
        $(link).focus();
        $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'true');
        e.preventDefault();
      }
    });

  $('.dropdown').on('click', '.menu-back-button', function(e) {
    clearSelection($(e.delegateTarget));
  });

  highlightCurrentPage();
};
